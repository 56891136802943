define("discourse/plugins/priceplow-discourse-connect/discourse/initializers/extend-for-priceplow-connect", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'phone_verification',
    initialize(c) {
      (0, _pluginApi.withPluginApi)('0.1', api => {
        api.modifyClass("component:user-field", {
          classNameBindings: [':user-field', 'field.field_type', "fmtedFieldName"],
          fmtedFieldName() {
            return this.get("field.name").replace(/[^a-z]/i, "-");
          },
          didInsertElement() {
            if (this.get("field.name") == "get-deal-alerts") {
              this.set("value", true);
            }
          }
        });
      });
    }
  };
});